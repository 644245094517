<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="regionColor"
            v-model="regionMsj")
            | {{ regionText }}
            v-btn(flat dark @click="regionMsj = false") {{$t('cerrar')}}
        v-dialog(v-model="regionModal" max-width="600" persistent)
            v-form(v-if="regionModal")
                v-card
                    v-card-title
                        | {{$t(!updateRegion ? 'Crear' : 'Editar')}} {{ $t('Región especial') }}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field(:label="$t('Nombre')" v-model="regionModel.name")
                            v-flex(xs12)
                                label {{ $t('Habilitado') }}
                                v-divider.mt-2
                                .switch-group
                                    label {{ $t('No') }}
                                    v-switch(:label="$t('Si')" color="success" v-model="regionModel.status")
                                br
                            v-flex(xs12)
                                h3 {{ $t('Agregar regiones') }}
                            v-flex(xs8)
                                v-select.mb-3(
                                    v-model="filter.state"
                                    :items='state'
                                    :label='$t("Estado/Departamento/Provincia")' 
                                    item-text="name"
                                    item-value="uuid"
                                    append-icon="keyboard_arrow_down"
                                    autocomplete
                                    :filter="vFilter"
                                    @input="fetchCity"
                                )
                            v-flex.vertical-center(xs4)
                                v-btn(color="primary" depressed small @click="addData(1, filter.state)") {{ $t('Agregar estado') }}
                            template(v-if="filter.state")
                                v-flex(xs8)
                                    v-select.mb-3(
                                        v-model="filter.city"
                                        :items='city' 
                                        :label='$t("Ciudad/Municipio")' 
                                        item-text="name"
                                        item-value="uuid"
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        @change="zonei=-1"
                                        @input="fetchZone"
                                        :filter="vFilter"
                                    )
                                v-flex.vertical-center(xs4)
                                    v-btn(color="primary" depressed small @click="addData(2, filter.city)") {{ $t('Agregar ciudad') }}
                            template(v-if="filter.city && zones.length")
                                template(v-for="(zone, i) in zones")
                                    v-flex(xs8)
                                        v-select.mb-3(
                                            v-model="filter.zones[i]"
                                            :items='zone'
                                            item-value="uuid"
                                            item-text="name"
                                            :label='zone[0].division'
                                            append-icon="keyboard_arrow_down"
                                            autocomplete
                                            :filter="vFilter"
                                            @change="zonei=i"
                                            @input="fetchZone"
                                        )
                                    v-flex.vertical-center(xs4)
                                        v-btn(color="primary" depressed small @click="addData(3, filter.zones[i], i)") {{ $t('Agregar zona') }}
                            v-flex(xs12)
                                h3 {{ $t('Listado de regiones') }}
                                p.mt-2(v-if="!regionModel.data.length") {{ $t('No se han encontrado regiones agregadas')}}
                                .table__overflow(v-if="regionModel.data.length")
                                    table.datatable.table
                                        thead
                                            tr
                                                th(align="left" v-for="header in headers") 
                                                    span.subheading {{ $t(header.text) }}
                                        tbody
                                            tr(v-for="(region, i) in regionModel.data" :key="'r' + i")
                                                td(align="left") {{ labelType(region) }}
                                                td(align="left") {{ region.item.name }}
                                                td(align="left")
                                                    v-icon.cur-p.primary--text(@click="regionModel.data.splice(i, 1)") close
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="close(),regionModal = false") {{ $t('Cancelar') }}
                            v-btn(:loading="loadingBtn" color="primary" large @click="saveRegion") {{ $t('Guardar') }}
</template>

<script>

import _m from 'lodash/merge'
import fn from 'mixins/fn'

export default {
    mixins: [fn],
    props: {
        countryid: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            regionSchema: {
                uuid     : '',
                name     : '',
                status   : true,
                data     : []
            },
            regionModel: {},
            regionMsj  : false,
            regionColor: '',
            regionText : '',
            regionModal: false,
            loadingBtn: false,
            updateRegion: false,
            filterSchema: {
                state    : '',
                city     : '',
                zones    : [],
            },
            filter   : {},
            state    : [],
            city     : [],
            zones    : [],
            zonei    : -1,
            headers: [
                {
                    text: 'Tipo'
                },
                {
                    text: 'Nombre'
                },
                {
                    text: 'Opciones'
                }
            ],
        }
    },
    created(){
        this.filter =_m({}, this.filterSchema)
    },
    methods: {
        
        async open(action, title, data = this.regionSchema){

            this.fetchState()

            this.updateRegion = (action == 'add' ? false: true) 
            this.regionModel  =_m({}, this.regionModel, data)
            this.regionModel.status  = this.regionModel.status == 1
            
            this.loadingBtn  = false
            this.regionModal = true
        },

        addData(type, typeid, idx){

            try{

                if(!typeid.length){

                    let msg

                    if(type == 1){
                        msg = 'Seleccione un estado'
                    }

                    if(type == 2){
                        msg = 'Seleccione una ciudad'
                    }

                    if(type == 3){
                        msg = 'Seleccione una zona'
                    }

                    throw msg
                }

                let item = {}

                let mapItem = (s) => {

                    let dat = {
                        uuid : s.uuid,
                        name : s.name
                    }

                    if(s.hasOwnProperty('division')){
                        dat.division = s.division
                    }

                    return dat
                }

                let exists = this.regionModel.data.some((s => {
                    return s.type == type && s.item.uuid == typeid
                }))

                if(type == 1){

                    item = this.state.filter((s) => s.uuid == typeid).map(mapItem)[0]
                    
                    if(exists){
                        throw 'El estado ya ha sido agregado'
                    }
                }

                if(type == 2){
                    
                    item = this.city.filter((s) => s.uuid == typeid).map(mapItem)[0]
                    
                    if(exists){
                        throw 'La ciudad ya ha sido agregada'
                    }
                }
                
                if(type == 3){
                    
                    item = this.zones[idx].filter((s) => s.uuid == typeid).map(mapItem)[0]
                    
                    if(exists){
                        throw 'La zona ya ha sido agregada'
                    }
                }

                this.regionModel.data.push({
                    type,
                    item
                })

            }catch(e){

                this.regionMsj  = true
                this.regionColor = 'error'
                this.regionText  = this.$t(e)
            }
        },

        cleanFilter(){

            this.zones  = []
            this.filter =_m({}, this.filterSchema)
            this.filter.zones = []
            this.regionModel.data = []
        },

        saveRegion(){

            this.loadingBtn = true

            let url          = '/region'
            let params       =_m({}, this.regionModel)
            params.status    = params.status ? 1: 0
            params.countryid = this.countryid
            params.data      = JSON.stringify(params.data)

            this.$api(this, (xhr) => {
                xhr.post(url, this.$qs.stringify(params)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.regionMsj  = true

                    if(data.response){
                        this.regionColor = 'success'
                        this.regionText = data.message
                        this.regionModal = false
                        this.cleanFilter()
                        this.$emit('success')
                    }else{
                        this.regionColor = 'error'
                        this.regionText = data.message
                    }

                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },

        fetchState(){

            this.$api(this, (xhr) => {
                xhr.get('/state', {
                    params: {
                        countryid: this.countryid
                    }
                }).then((r) => {

                    let data = r.data
                    if(data.response){
                        this.state = data.data
                    }
                }).catch(() => {})
            })
        },

        fetchCity(){

            this.zonei = -1
            this.zones = []

            this.$api(this, (xhr) => {
                xhr.get('/city', {
                    params: {
                        stateid: this.filter.state 
                    }
                }).then((r) => {

                    let data = r.data
                    if(data.response){
                        this.city = data.data
                    }
                }).catch(() => {})
            })
        },

        fetchZone(item){

            return new Promise((resolve) => {

                this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei+1)

                this.$api(this, (xhr) => {
                    xhr.get('/zone', {
                        params: {
                            cityid: this.filter.city,
                            parent: this.zonei == -1 ? 0 : item,
                            ab: 1,
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            if(data.data.length){
                                this.filter.zones.push('')
                                this.zones.push(data.data)
                            }
                        }
                        resolve()
                    })
                })
            })
        },

        close(){
            this.cleanFilter()
        },

        labelType(item){

            let type  = item.type
            let label = ''

            if(type == 1){
                label = this.$t('Estado/Departamento/Provincia')
            }

            if(type == 2){
                label = this.$t('Ciudad/Municipio')
            }

            if(type == 3){
                label = this.$t(item.item.division)
            }

            return label
        }
    }
}
</script>