<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                    :timeout="3000",
                    :top="true"
                    :right="true"
                    :color="regionColor"
                    v-model="regionMsj")
                    | {{ regionText }}
                    v-btn(flat dark @click="regionMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1
                        | {{$t('Regiones especiales')}} {{ !Array.isArray(info) && info.name.length ? ' - '+info.name : '' }}
                    v-btn.primary(depressed @click="backLink") {{$t('Volver')}}
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-data-table(
                            :headers='headers'
                            :items='regions'
                            :pagination.sync='pagination'
                            :rows-per-page-items="$root.rowsPageItems"
                            :rows-per-page-text="$root.rowsPageText"
                            :total-items="total"
                            :loading="loading"
                            item-key='uuid'
                        )
                            template(slot="no-results")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot="no-data")
                                | {{$t('No se han encontrado resultados')}}
                            template(slot='headers' slot-scope='props')
                                tr
                                    th(v-for='header in props.headers' :key='header.text' :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" @click='changeSort(header.value)' :align="header.align")
                                        span.subheading {{ $t(header.text) }}
                                        v-icon(small v-if="header.sortable") arrow_upward
                            template(slot='items' slot-scope='props')
                                tr
                                    td {{ props.item.name }}
                                    td {{ $t(props.item.status == 1 ? 'Activo' : 'Inactivo') }}
                                    td.text-xs-right
                                        v-icon.cur-p.primary--text(@click="$refs.manage.open('edit', $t('Editar'), props.item)") edit
                                        v-icon.cur-p.primary--text(@click="$refs.delete.open(props.item)") close
                            template(slot='pageText' slot-scope='props')
                                | {{ $t('Filas') }} {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                    v-btn(@click.native="$refs.manage.open('add', $t('Agregar'))" fixed dark fab bottom right color='primary')
                        v-icon add
        manage-special-region(ref="manage" :countryid="typeid" @success="cleanFilter")
        confirm(ref="delete" @action="deleteSpecialRegion" :title="$t('Eliminar región especial')" :message="$t('¿Estás seguro de eliminar esta región especial?')")
</template>

<script>

    import auth from 'mixins/auth'
    import _ from 'lodash/debounce'
    import _m from 'lodash/merge'
    import ManageSpecialRegion from 'components/ManageSpecialRegion'
    
    export default {
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Regiones especiales')
            }
        },
        data() {
            return {
                regionMsj  : false,
                regionColor: '',
                regionText : '',
                preload: false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query    : '',
                    status   : []
                },
                regions      : [],
                loadingBtn: false,
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'name',
                        sortable: true
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status',
                        sortable: true
                    },
                    {
                        text: 'Opciones',
                        align: 'right',
                        value: 'options',
                        sortable: false
                    }
                ],
                info: {
                    name: ''
                },
                total: 0,
                loading: false,
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload = true
                    this.total = 0
                    this.regions = []

                    this.fetchRegion()
                },
                deep: true
            },
            pagination: {
                handler () {
                    this.fetchRegion()
                },
                deep: true
            }
        },
        computed: {
            type(){
                return this.$route.query.t
            },
            typeid(){
                return this.$route.query.tid
            }
        },
        created(){
            this.filter = _m({}, this.filterSchema)
        },
        methods: {

            fetchRegion:  _(function(){
                
                this.loading = true

                let url    = '/region'
                let params = {
                    filter   : JSON.stringify(this.filter),
                    countryid: this.typeid,
                    limit    : this.pagination.rowsPerPage,
                    offset   : (this.pagination.page-1)*this.pagination.rowsPerPage,
                    sort     : this.pagination.sortBy,
                    order    : (this.pagination.descending ? 'DESC' : 'ASC'),
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, { params: params }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){
                            
                            this.total = data.data.total
                            this.regions = data.data

                            if(this.pagination.page == 1){
                                this.info = data.info
                            }
                        }
                    }).catch(() => {})
                })
            }, 500),

            changeSort (column) {

                // No sortable
                if(column == 'options'){
                    return
                }
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            cleanFilter(){

                this.pagination.sortBy = ''
                this.pagination.descending = ''

                this.filter = _m({}, this.filterSchema)
            },

            backLink(){

                let q = {
                    t   : 1,
                    tid : this.typeid
                }

                return this.$router.replace({ path: '/region', query: q })
            },

            deleteSpecialRegion({uuid, countryid}){

                this.$refs.delete.$emit('playLoader')
                this.$api(this, (xhr) => {
                    xhr.delete('/region?uuid=' + uuid + '&countryid=' + countryid).then((r) => {
                        
                        this.$refs.delete.$emit('stopLoader')
                        this.regionMsj = true

                        let data = r.data

                        if(data.response){
                            this.regionColor = 'success'
                            this.regionText = data.message
                            this.$refs.delete.close()
                            this.fetchRegion()
                        }else{
                            this.regionColor = 'error'
                            this.regionText = data.message
                        }
                    }).catch(() => {
                        this.$refs.delete.$emit('stopLoader')
                        this.$refs.delete.close()
                    })
                })
            }
        },
        components: {
            ManageSpecialRegion
        }
    }
    
</script>